import $ from 'jQuery';
global.$ = global.jQuery = $;
import Swiper, { Navigation, Pagination, Autoplay } from 'Swiper';

global.isDebug = true;
global.__c__ = function(a){
  if(isDebug){
    console.log(a);
  }
}

var pow = Math.pow,
  sqrt = Math.sqrt,
  sin = Math.sin,
  cos = Math.cos,
  PI = Math.PI,
  c1 = 1.70158,
  c2 = c1 * 1.525,
  c3 = c1 + 1,
  c4 = ( 2 * PI ) / 3,
  c5 = ( 2 * PI ) / 4.5;
// x is the fraction of animation progress, in the range 0..1
function bounceOut(x) {
  var n1 = 7.5625,
    d1 = 2.75;
  if ( x < 1/d1 ) {
    return n1*x*x;
  } else if ( x < 2/d1 ) {
    return n1*(x-=(1.5/d1))*x + 0.75;
  } else if ( x < 2.5/d1 ) {
    return n1*(x-=(2.25/d1))*x + 0.9375;
  } else {
    return n1*(x-=(2.625/d1))*x + 0.984375;
  }
}
$.extend( $.easing,
  {
    def: 'easeOutQuad',
    swing: function (x) {
      return $.easing[$.easing.def](x);
    },
    easeInQuad: function (x) {
      return x * x;
    },
    easeOutQuad: function (x) {
      return 1 - ( 1 - x ) * ( 1 - x );
    },
    easeInOutQuad: function (x) {
      return x < 0.5 ?
        2 * x * x :
        1 - pow( -2 * x + 2, 2 ) / 2;
    },
    easeInCubic: function (x) {
      return x * x * x;
    },
    easeOutCubic: function (x) {
      return 1 - pow( 1 - x, 3 );
    },
    easeInOutCubic: function (x) {
      return x < 0.5 ?
        4 * x * x * x :
        1 - pow( -2 * x + 2, 3 ) / 2;
    },
    easeInQuart: function (x) {
      return x * x * x * x;
    },
    easeOutQuart: function (x) {
      return 1 - pow( 1 - x, 4 );
    },
    easeInOutQuart: function (x) {
      return x < 0.5 ?
        8 * x * x * x * x :
        1 - pow( -2 * x + 2, 4 ) / 2;
    },
    easeInQuint: function (x) {
      return x * x * x * x * x;
    },
    easeOutQuint: function (x) {
      return 1 - pow( 1 - x, 5 );
    },
    easeInOutQuint: function (x) {
      return x < 0.5 ?
        16 * x * x * x * x * x :
        1 - pow( -2 * x + 2, 5 ) / 2;
    },
    easeInSine: function (x) {
      return 1 - cos( x * PI/2 );
    },
    easeOutSine: function (x) {
      return sin( x * PI/2 );
    },
    easeInOutSine: function (x) {
      return -( cos( PI * x ) - 1 ) / 2;
    },
    easeInExpo: function (x) {
      return x === 0 ? 0 : pow( 2, 10 * x - 10 );
    },
    easeOutExpo: function (x) {
      return x === 1 ? 1 : 1 - pow( 2, -10 * x );
    },
    easeInOutExpo: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        pow( 2, 20 * x - 10 ) / 2 :
        ( 2 - pow( 2, -20 * x + 10 ) ) / 2;
    },
    easeInCirc: function (x) {
      return 1 - sqrt( 1 - pow( x, 2 ) );
    },
    easeOutCirc: function (x) {
      return sqrt( 1 - pow( x - 1, 2 ) );
    },
    easeInOutCirc: function (x) {
      return x < 0.5 ?
        ( 1 - sqrt( 1 - pow( 2 * x, 2 ) ) ) / 2 :
        ( sqrt( 1 - pow( -2 * x + 2, 2 ) ) + 1 ) / 2;
    },
    easeInElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        -pow( 2, 10 * x - 10 ) * sin( ( x * 10 - 10.75 ) * c4 );
    },
    easeOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        pow( 2, -10 * x ) * sin( ( x * 10 - 0.75 ) * c4 ) + 1;
    },
    easeInOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        -( pow( 2, 20 * x - 10 ) * sin( ( 20 * x - 11.125 ) * c5 )) / 2 :
        pow( 2, -20 * x + 10 ) * sin( ( 20 * x - 11.125 ) * c5 ) / 2 + 1;
    },
    easeInBack: function (x) {
      return c3 * x * x * x - c1 * x * x;
    },
    easeOutBack: function (x) {
      return 1 + c3 * pow( x - 1, 3 ) + c1 * pow( x - 1, 2 );
    },
    easeInOutBack: function (x) {
      return x < 0.5 ?
        ( pow( 2 * x, 2 ) * ( ( c2 + 1 ) * 2 * x - c2 ) ) / 2 :
        ( pow( 2 * x - 2, 2 ) *( ( c2 + 1 ) * ( x * 2 - 2 ) + c2 ) + 2 ) / 2;
    },
    easeInBounce: function (x) {
      return 1 - bounceOut( 1 - x );
    },
    easeOutBounce: bounceOut,
    easeInOutBounce: function (x) {
      return x < 0.5 ?
        ( 1 - bounceOut( 1 - 2 * x ) ) / 2 :
        ( 1 + bounceOut( 2 * x - 1 ) ) / 2;
    }
  });

global._isSP = undefined;

if(window.outerWidth < 829){
  _isSP = true;
}else{
  _isSP = false;
}

class SwitchImage{
  constructor($target){
    this.duration = $target.data("switch-duration") ? $target.data("switch-duration") | 0 : 3000;
    this.timer = null;
    this.images = $target.find("img");
    this.max = this.images.length;
    this.count = 0;
    this.name = (function(){
      var __class = $target.attr("class");
      return __class;
    })();
  }
  start(){
    var _this = this;
    _this.change();
    _this.timer = setInterval(function(){
      _this.change();
    }, _this.duration);
  }
  stop(){
    var _this = this;
    clearInterval(_this.timer);
  }
  change(){
    var _this = this;
    _this.images.each(function(i){
      if(i === _this.count){
        $(_this.images[i]).css({display: "block"});
      }else{
        $(_this.images[i]).css({display: "none"});
      }
    });
    _this.count++;
    _this.count = _this.count % _this.max;
  }
  setDuration(duration){
    var _this = this;
    _this.duration = duration;
  }
}

class Drone {
  constructor(target, svg){
    this.target = target;
    this.svg = svg;
    var viewpoint = svg.getAttribute('viewBox').split(' ').map(function(val){return val;});
    this.viewBox = {
      x: viewpoint[0],
      y: viewpoint[1],
      width: viewpoint[2],
      height: viewpoint[3]
    }
    this.path = svg.getElementsByTagName('path')[0];
    this.pathLength = 0;
    this.maxPathLength = this.path.getTotalLength();
    this.speed = 2;
    this.svgClientRect = this.svg.getBoundingClientRect();
    this.range = this.svgClientRect.height;

    this.step();
  }
  step(){
    var pt = this.path.getPointAtLength(this.pathLength);
    var x = pt.x * this.svgClientRect.width / this.viewBox.width;
    var y = pt.y * this.svgClientRect.height / this.viewBox.height;
    this.target.style.transform = `translate3d(${x}px, ${y}px, 0) translate3d(-50%, -50%, 0)`;

    if(this.pathLength > this.maxPathLength - 100){
      this.target.classList.add("end");
    }else{
      this.target.classList.remove("end");
    }
  }

  update(scrollAmaount){
    this.pathLength = (scrollAmaount * this.speed) | 0;
    this.step();
  }

  resize(){
    this.svgClientRect = this.svg.getBoundingClientRect();
    this.range = this.svgClientRect.height;
  }

  setStep(range){
    this.speed = this.maxPathLength / range;
  }
}

var Init = function(){
  var $window = $(window);
  var $html = $("html");
  var $body = $("body");

  var $droneshow = $("#droneshow");

  if($droneshow.length > 0) {
    var $event = $("#event");

    var blue = new Drone(document.getElementById("blue_drone"), document.getElementById("blue_line"));
    var yellow = new Drone(document.getElementById("yellow_drone"), document.getElementById("yellow_line"));
    var green = new Drone(document.getElementById("green_drone"), document.getElementById("green_line"));

    var scrollRange = {
      start: $event.offset().top,
      end: $event.offset().top + $event.outerHeight() - (window.screen.height / 2),
    };
    var _range = scrollRange.end - scrollRange.start;
    blue.setStep(_range);
    yellow.setStep(_range);
    green.setStep(_range);

    var DroneScroll = function(){
      if($window.scrollTop() > scrollRange.start && $window.scrollTop() < scrollRange.end) {
        var now = $window.scrollTop() - scrollRange.start;
        blue.update(now);
        yellow.update(now);
        green.update(now);
      }
    };

    var $outline = $("#outline");
    var $outlineCloud = $(".outline--cloud");
    var $outlineVideo = $(".outline--video");

    var DroneShowResize = function(){
      var outlineHeight = $outline.outerHeight();
      $outlineCloud.css({height: outlineHeight});
      $outlineVideo.css({height: outlineHeight});

      var scrollRange = {
        start: $event.offset().top,
        end: $event.offset().top + $event.outerHeight() - (window.screen.height / 2),
      };
      _range = scrollRange.end - scrollRange.start;
      blue.setStep(_range);
      yellow.setStep(_range);
      green.setStep(_range);
    };

    $window.on("scroll", DroneScroll);

    // DroneShow Video
    var _video = document.getElementById("video");
    if(_video !== void 0) {
      _video.volume = .2;

      var VideoEnded = function(event){
        event.currentTarget.currentTime = 0;
      };

      _video.addEventListener("ended", VideoEnded);
    }
  }



  var SelectModalOpen = function(event){
    // console.log("SelectModalOpen");
    var _$target = $(event.currentTarget);
    var _parentId = _$target.data("modal-id");
    var _id = _$target.data("modal-content");
    var $modal = $("#" + _parentId);
    var $modalOverlay = $modal.find(".modal-overlay");
    var $modalClose = $modal.find(".modal--close");
    var $modalContents = $modal.find(".modal-content");
    $html.addClass("is-modal");
    // console.log("SelectModalOpen");
    $modalContents.each(function(){
      var __$this = $(this);
      var __$box = __$this.find(".modal-box");
      if(__$this.data("modal-content") === _id){
        var _url = __$this.data("modal-url");
        var _color = __$this.data("modal-color");
        var _overlay = __$this.data("modal-overlay");
        var _opacity = __$this.data("modal-opacity") * 1;
        var _youtube = __$this.data("modal-youtube");

        if(_url !== void 0){
          __$this.css({
            "background": "url(" + _url + ") center center / 100% 100%"
          });
        }else if(_color !== void 0){
          __$this.css({
            "background": _color
          });
        }

        if(_youtube !== void 0){
          var __$movie = __$this.find(".movie").empty();
          var __$yt = $('<iframe width="560" height="315" src="https://www.youtube.com/embed/' + _youtube + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
          __$movie.append(__$yt);
        }

        __$this.addClass("modal-content--open");
        $modalOverlay.css({
          "background": _overlay,
          opacity: _opacity,
        });


        var _timer = setTimeout(function(){
          if(__$box.outerHeight() > $window.outerHeight()){
            __$this.addClass("not-centering");
          }

          __$this.scrollTop(0);

          __$this.addClass("show");
          clearTimeout(_timer);
        }, 100);
      }
    });
    $modal.css({display: "block"});
    $modalOverlay.on("click", SelectModalClose);
    $modalClose.on("click", SelectModalClose);
  };

  var SelectModalClose = function(){
    // console.log("SelectModalClose");
    var $modal = $(".modal");
    var $modalOverlay = $modal.find(".modal-overlay");
    var $modalClose = $modal.find(".modal--close");
    var $modalContents = $modal.find(".modal-content");
    var _youtube = $modalContents.data("modal-youtube");
    if(_youtube !== void 0){
      $modalContents.find(".movie").empty();
    }

    $modalContents.removeClass("show");
    $modalOverlay.stop().animate({opacity: 0}, 300, function(){
      $modal.css({display: ""});
      $html.removeClass("is-modal");
      $modalContents.each(function(){
        var __$this = $(this);
        __$this
          .css({"background": ""})
          .removeClass("modal-content--open")
          .removeClass("not-centering");
      });
      $modalOverlay.css({
        "background": "",
        opacity: "",
      });

      $modalOverlay.off("click", SelectModalClose);
      $modalClose.off("click", SelectModalClose);
    });
  };

  $("body").on("click", ".modal--btn", SelectModalOpen);

  var AnimationStart = function($container){
    // console.log("AnimationStart");
    var _$wait = $container.find(".wait");
    _$wait.each(function(){
      var __$this = $(this);
      var __delay = __$this.data("delay");
      var __timeout = setTimeout(function(){
        __$this
          .removeClass("wait")
          .addClass("animation");
        clearTimeout(__timeout);
      }, __delay)
    });
  };

  var ResetAnimate = function($container){
    // console.log("ResetAnimate");
    var _$animate = $container.find(".animation");
    var _$animating = $container.find(".animating");
    _$animate.each(function(){
      var __$this = $(this);
      __$this
        .addClass("wait")
        .removeClass("animation");
    });
    _$animating.each(function(){
      var __$this = $(this);
      __$this
        .addClass("waiting")
        .removeClass("animating");
    });

  };

  var windowInnerHeight = window.innerHeight;
  var isEventSetAnimation = true;
  var SetAnimation = function(){
    var $section = $(".waiting");
    var sectionLen = $section.length;
    var timing, mainHeight, mainTop;
    mainTop = 0;
    timing = windowInnerHeight - (windowInnerHeight / 5);

    if(sectionLen === 0){
      isEventSetAnimation = false;
    } else {
      for(var i = 0; i < sectionLen; i++){
        var $wait = $($section[i]);
        var offsetTop = $wait.offset().top;

        if($window.scrollTop() + timing >= offsetTop && $window.scrollTop() <= offsetTop){
          var _delay = $wait.data("delay");
          if(_delay === void 0){
            _delay = 0;
          }
          $wait.delay(_delay).queue(function(){
            var __$this = $(this);
            __$this.removeClass("waiting").addClass('animating');
            __$this.dequeue();
          });
        }
      }
    }
  };

  var AnimationScroller = function(){};

  // 切り替えアニメーション
  var $switch = $(".switch");
  $switch.each(function(){
    var _s = new SwitchImage($(this));
    var _delay = $(this).data("switch-delay") ? $(this).data("switch-delay") | 0 : 0;
    var _timer = setTimeout(function(){
      _s.start();
      clearTimeout(_timer);
    }, _delay);
  });

  //スクロールでスウィング
  var $swing = $(".scroll-swing");
  var scrollCount = 5;
  var swing = function(){
    var _scroll = window.scrollY | 0;
    if(_scroll % scrollCount === 0){
      var _amout = Math.cos(_scroll) * 10 | 0;
      $swing.css({
        "transform": "rotate(" + (_amout / 2) + "deg)",
        "-webkit-transform": "rotate(" + (_amout / 2) + "deg)",
      });
    }
  };

  // メインのアニメーション
  var StartTopAnimation = function(){
    var _t = setTimeout(function(){
      var $eSlides = null;
      var eSlider = new Swiper(".e-slider", {
        modules: [Navigation, Autoplay],
        loop: true,
        loopAdditionalSlides: 1,
        speed: 300,
        slidesPerView: 'auto',
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        on: {
          slideNextTransitionStart : function(){
            if($eSlides === null){
              $eSlides = $(".e-slider").find(".swiper-slide");
            }
            $eSlides.removeClass("stopped").addClass("moving");
          },
          slideNextTransitionEnd : function(){
            if($eSlides === null){
              $eSlides = $(".e-slider").find(".swiper-slide");
            }
            $eSlides.removeClass("moving").addClass("stopped");
          },
          afterInit : function(){
            var _len = $(".e-slider").find(".swiper-slide").not(".swiper-slide-duplicate").length;
            $(".swiper-pagination").find(".swiper-pagination-bullet").addClass("count" + _len);
          }
        },
        navigation: {
          nextEl: '.e-slider-next',
          prevEl: '.e-slider-prev',
        }
      });

      // ScrollAnimatiopn
      AnimationScroller = function(){
        if(isEventSetAnimation) SetAnimation();
        swing();
      };

      AnimationStart($("#fv"));

      $window.on("scroll", AnimationScroller);
      //AnimationScroller();

      clearTimeout(_t);
    }, 100);
  };
  StartTopAnimation();

  $(".pagetop").on("click", function(){
    $("html, body").stop().animate({scrollTop: 0}, 1000, "easeInOutCirc");
  });

  $(".anchor").on("click", function(){
    var _$this = $(this);
    var _$target = $(_$this.attr("href"));
    $("html, body").stop().animate({scrollTop: _$target.offset().top}, 1000, "easeInOutCirc");
    return false;
  });


  var Resize = function(){
    windowInnerHeight = window.innerHeight
    if($window.width() < 829){
      if(!_isSP) _isSP = true;
    }else{
      if(_isSP) _isSP = false;
    }

    if(DroneShowResize) {
      DroneShowResize();
    }
  };
  $window.on("resize", Resize);
  Resize();

};

window.addEventListener("load", Init);


// YOUTUBE
var youtubeFunc = function(){
  var tag = document.createElement('script');

  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  var player;
  global.onYouTubeIframeAPIReady = function() {
    player = new YT.Player('youtube', {
      height: '360',
      width: '640',
      videoId: 'Ei5mEfBCMm4',
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  };

  var movie = document.getElementById("movie");
  global.onPlayerReady = function(event) {
    //alert("ready!");
    //event.target.playVideo();
    movie.addEventListener('click', VideoOnOff);
  }

  var VideoOnOff = function(){
    if(player.getPlayerState() === 1){
      //movie.style.display = '';
      player.pauseVideo();
    } else if(player.getPlayerState() === 0 || player.getPlayerState() === 2 || player.getPlayerState() === 5){
      movie.style.display = 'none';
      player.playVideo();
    }
  }

  /*
  * YT.PlayerState.PAUSED
  * YT.PlayerState.ENDED
  * YT.PlayerState.PLAYING
  * */

  global.onPlayerStateChange = function(event) {
    if (event.data == YT.PlayerState.ENDED) {
      // end
      player.seekTo(0);
      player.pauseVideo();
    } else if(event.data == YT.PlayerState.PAUSED) {
      // pause
      //movie.style.display = "";
    } else if(event.data == YT.PlayerState.PLAYING) {
      // playing
      //movie.style.display = "none";
    }
  }

};